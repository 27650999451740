import React from "react";
import PropTypes from "prop-types";

import {
  SLIDER_NEXT_BUTTON_SVG,
  SLIDER_PREV_BUTTON_SVG,
} from "../../../constants/svgConstants";

function ArrowsWithNumber({ numberOfPage, lengthOfPages, onPrev, onNext }) {
  return (
    <div className="club-arrow-nav-block">
      <div className="club-arrow-nav-number">
        {lengthOfPages !== 0 ? numberOfPage : 0}
        <span className="clup-arrow-nav-amount">/{lengthOfPages}</span>
      </div>

      <div
        className={
          numberOfPage <= 1
            ? "btn-arrow club-nav-btn-arrow disabled-btn-arrow"
            : "btn-arrow club-nav-btn-arrow"
        }
        onClick={onPrev}
      >
        {SLIDER_PREV_BUTTON_SVG()}
      </div>
      <div
        className={
          lengthOfPages == numberOfPage
            ? "btn-arrow club-nav-btn-arrow disabled-btn-arrow"
            : "btn-arrow club-nav-btn-arrow"
        }
        onClick={onNext}
      >
        {SLIDER_NEXT_BUTTON_SVG()}
      </div>
    </div>
  );
}

ArrowsWithNumber.propTypes = {
  numberOfPage: PropTypes.number,
  lengthOfPages: PropTypes.number,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
};

ArrowsWithNumber.defaultProps = {
  numberOfPage: 0,
  lengthOfPages: 0,
  onPrev: () => {},
  onNext: () => {},
};

export { ArrowsWithNumber };
