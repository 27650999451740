import React, { useMemo } from "react";

import { OptionAlert } from "./OptionAlert/OptionAlert";
import { OptionCardWrapper } from "./OptionCard/OptionCardWrapper";
import { CardButton } from "../../../../../common/CardButton/CardButton";
import { useTranslation } from "next-i18next";
import { useToggle } from "../../../../../../hooks/useToggle";

export const OptionsBlock = ({
  options,
  visitOptions = [],
  title,
  visitId,
  editable = true,
}) => {
  const [t] = useTranslation();
  const availableOptions = useMemo(() => {
    return options.map((option) => {
      const { id } = option;
      const optionId = visitOptions.find(({ optionId }) => optionId === id)?.id;
      return {
        ...option,
        ordered: !!optionId,
        optionId,
      };
    });
  }, [options, visitOptions]);

  const [showModal, toggle] = useToggle(false);

  return (
    <>
      {showModal && (
        <OptionAlert
          title={title}
          buttonTitles={buttonTitles}
          availableOptions={availableOptions}
          onClose={toggle}
          visitId={visitId}
          editable={editable}
        />
      )}

      <div className="training-options">
        <div className="main-title-block training-options__title-block">
          <h3 className="subtitle training-options__title">{title}</h3>

          {editable && (
            <CardButton
              title={t(visitOptions.length > 0 ? "change" : "add")}
              className={"primary-button training-options__button"}
              onClick={toggle}
            />
          )}
        </div>

        {visitOptions.map(({ id, ...props }) => (
          <OptionCardWrapper
            {...props}
            id={id}
            visitId={visitId}
            key={id}
            title={t("remove")}
            editable={editable}
            ordered={true}
          />
        ))}
      </div>
      <style jsx global>{`
        @import "./OptionsBlock.scss";
      
      `}</style>
    </>
  );
};
