import React, { useMemo, useState } from "react";

import ModalDropdownId from "../../../../common/ModalDropdown/ModalDropdownId";
import { useTranslation } from "next-i18next";

function HeaderModalActivity({ activities, buttonFunc }) {
  const [t] = useTranslation();
  const [activeId, setActiveId] = useState(-1);

  const items = useMemo(
    () => [
      { id: -1, title: t("all"), secondTitle: t("choose_sport_activity") },
      ...activities,
    ],
    [activities]
  );

  return (
    <>
      <ModalDropdownId
        items={items}
        activeId={activeId}
        setActiveId={setActiveId}
        defaultTitleId={-1}
      />

      <button
        className="header-modal__button"
        onClick={() => buttonFunc(activeId)}
      >
        {t('next')}
      </button>
    </>
  );
}

export default HeaderModalActivity;
