import React, { memo, useContext } from "react";

import Link from "next/link";

import HeaderModal from "../HeaderModal/HeaderModal";

import { NavbarFooterContext } from "../../containers/NavbarFooterContext";

import { clsx } from "clsx";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useToggle } from "../../../../../hooks/useToggle";
import { useAuth } from "../../../../../utils/auth";

import { setAuthorization } from "../../../../../utils/redux/actionCreators/actions";

import { HeaderAppLinks } from "./HeaderAppLinks";

const Header = memo(
  ({
    title,
    image,
    halls,
    activities,
    imageTitleEnabled = false,
    hasPortal = false,
    portalSlug,
    applicationGooglePlay,
    applicationAppStore,
    applicationCallToActionMessage,
  }) => {
    const [t] = useTranslation();

    const dispatch = useDispatch();

    const router = useRouter();
    const {
      query: { club },
    } = router;

    const { isAuthorized } = useAuth();

    const { scheduleDefaultView } = useContext(NavbarFooterContext);

    const [showModal, toggleModal, onCloseModal] = useToggle(false);
 
    return (
      <>
        {!!halls?.length && showModal && (
          <HeaderModal
            onClose={onCloseModal}
            halls={halls}
            activities={activities}
            scheduleDefaultView={scheduleDefaultView}
          />
        )}

        <div className="club-header club-page--background">
          <div className="club-header__wrapper-bg-img">
            {image && (
              <Image
                className="club-header__bg-img"
                src={image}
                blurDataURL={image}
                placeholder="blur"
                fill
                sizes="100vw"
                priority={true}
                quality={100}
                alt="banner-img"
              />
            )}
          </div>

          <div
            className={clsx("club-header__wrapper", {
              "club-header__wrapper--default": !imageTitleEnabled,
            })}
          >
            <div className="club-header__inner container">
              <div
                className={clsx("club-header__content", {
                  "club-header__content--full": hasPortal,
                })}
              >
                {imageTitleEnabled && (
                  <h1 className="club-header__title">{title}</h1>
                )}

                <div className="club-header__buttons-block">
                  {hasPortal && (
                    <Link
                      href={`/portal/${portalSlug}`}
                      className="club-header__button"
                    >
                      {t("select_club")}
                    </Link>
                  )}
                  <button
                    className="club-header__button"
                    onClick={() => {
                      isAuthorized
                        ? toggleModal()
                        : dispatch(
                            setAuthorization({
                              setHeader: "signup",
                              nextUrl: `/club/${club}/schedule/${scheduleDefaultView}`,
                            })
                          );
                    }}
                  >
                    {t("sign_up_for_workout")}
                  </button>

                  <Link
                    href={`/club/${club}/schedule/${scheduleDefaultView}`}
                    className="club-header__button"
                  >
                    {t("schedule")}
                  </Link>
                </div>
                {imageTitleEnabled && (
                  <HeaderAppLinks
                    applicationGooglePlay={applicationGooglePlay}
                    applicationAppStore={applicationAppStore}
                    applicationCallToActionMessage={
                      applicationCallToActionMessage
                    }
                  />
                )}
              </div>
              {!imageTitleEnabled && (
                <HeaderAppLinks
                  applicationGooglePlay={applicationGooglePlay}
                  applicationAppStore={applicationAppStore}
                  applicationCallToActionMessage={
                    applicationCallToActionMessage
                  }
                />
              )}
            </div>
          </div>

          <style jsx global>{`
            @import "./Header.scss?1s";
          `}</style>
        </div>
      </>
    );
  }
);

Header.defaultProps = {
  title: "",
  image: "",
  halls: [],
  activities: [],
};

export default Header;
