import React, { useEffect, useState } from "react";

import { SwiperBlock } from "../../SwiperBlock";

import { CHREST_SVG } from "../../../../../../constants/svgClubConstants";

const params = {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 20,
  grabCursor: true,
  loop: true,
  speed: 700,

  scrollbar: {
    el: ".subscription-cards-swiper-scrollbar",
    hide: false,
    draggable: true,
  },

  breakpoints: {
    1100: {
      slidesPerView: 4,
      centeredSlides: false,
    },
    810: {
      slidesPerView: 3,
      centeredSlides: false,
    },
    500: {
      slidesPerView: 2,
      centeredSlides: false,
    },
  },
};

function ProductsBlock({ activeClub }) {
  const [allProducts, setAllProducts] = useState([]);
  // useEffect(() => {
  //   if (!activeClub) return;

  //   const getProducts = async () => {
  //     try {
  //       const {
  //         data: { products },
  //       } = await fetchByGql({
  //         key: activeClub,
  //         query,
  //         variables: { activeClub },
  //         setErrorPage,
  //         language: language,
  //         history,
  //         checkTokens,
  //         accessToken: tokens.accessToken,
  //       });

  //       setAllProducts(products);
  //     } catch (error) {
  //       setErrorPage(true);
  //       return error;
  //     }
  //   };

  //   getProducts();
  // }, [activeClub, language, tokens.date]);

  return (
    <SwiperBlock params={params}>
      {!!allProducts &&
        allProducts.map(({ id, imageProduct, price, title }) => {
          const image = imageProduct[0].image;
          return (
            <div className="training-product" key={id}>
              <div className="training-product__inner-slide">
                <div
                  className="training-product__image"
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
                <div className="training-product__content product-content">
                  <h3 className="product-content__name">{title}</h3>
                  <div className="product-content__price-row">
                    <div className="product-content__price-block">
                      <div className="product-content__discount"></div>
                      <div className="product-content__price">{price}</div>
                    </div>
                    <div className="card-item product-content__btn">
                      {CHREST_SVG}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <style jsx global>{`
        @import "./ProductsBlock.scss";
      `}</style>
    </SwiperBlock>
  );
}

export default ProductsBlock;
