import React from "react";

import { SwiperBlock } from "../../SwiperBlock";
import Link from "next/link";

import TrainerMobileBlock from "../../TrainerBlock/TrainerMobileBlock/TrainerMobileBlock";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import Image from "next/image";
import { useMediaQuery } from "../../../../../../hooks";
import { replaceNewLineToBrTag } from "../../../../../../utils/html/htmlHelper";

const params = {
  centeredSlides: true,
  slidesPerView: 1,

  grabCursor: true,

  navigation: {
    nextEl: ".trainer-block-swiper-next-btn",
    prevEl: ".trainer-block-swiper-prev-btn",
  },
};

function InstructorBlock({ instructors }) {
  const [t] = useTranslation();
  const router = useRouter();
  const {
    query: { club },
    asPath,
  } = router;

  const isDesktop = useMediaQuery("(min-width:768px)");

  return (
    <>
      <section className="training-instructors">
        <div className="training__wrapper training__wrapper--grey">
          <div className="container">
            <div className="training-instructors__title main-title">
              {t(instructors.length == 1 ? "instructor" : "instructors")}
            </div>
            <div
              className={`${
                instructors.length > 1
                  ? "training-block-swiper"
                  : "training-block-swiper-one"
              }`}
            >
              {!isDesktop ? (
                <TrainerMobileBlock
                  allInstructors={instructors}
                  scheduleButton={t("schedule")}
                  isScheduleLink={false}
                  isDescription={true}
                  pathbefore={asPath}
                />
              ) : (
                <div className="club-trainers-block-desctop">
                  <SwiperBlock params={params}>
                    {instructors.map(
                      ({
                        id,
                        name,
                        instructorImage,
                        instructorDescription,
                      }) => (
                        <Link
                          href={{
                            pathname: `/club/${club}/instructor/${id}`,

                            query: { pathbefore: asPath },
                          }}
                          as={`/club/${club}/instructor/${id}`}
                          key={id}
                          className="training-instructor"
                        >
                          <div className="training-instructor__inner-slide">
                            {!!instructorImage && (
                              <div className="training-instructor__wrapper-image">
                                <Image
                                  className="training-instructor__image"
                                  alt={`instructor${id}`}
                                  src={instructorImage}
                                  fill
                                />
                              </div>
                            )}

                            <div className="training-instructor__content instructor-content">
                              <h3 className="instructor-content-name instructor-content__name">
                                {name}
                              </h3>
                              <div
                                className="instructor-content__text"
                                dangerouslySetInnerHTML={{
                                  __html: replaceNewLineToBrTag(
                                    instructorDescription
                                  ),
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                      )
                    )}
                  </SwiperBlock>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <style jsx global>{`
        @import "./InstructorBlock.scss?7";

        @import "../../TrainerBlock/TrainerDesctopBlock/TrainerDesctopBlock.scss?8";
      `}</style>
    </>
  );
}

export default InstructorBlock;
