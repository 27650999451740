import { useCallback, useEffect, useState } from "react";

const MEDIA_SIZES = [
  { firstSize: 0, lastSize: 480, elementBySize: "mobile" },
  { firstSize: 481, lastSize: 767, elementBySize: "day" },
];

export const useMediaSize = (media = MEDIA_SIZES) => {
  const getMediaElement = useCallback(() => {
    if (typeof window === "undefined") return {};

    const windowWidth = window.innerWidth;

    const activeEl =
      media.find(
        ({ firstSize, lastSize }) =>
          windowWidth > firstSize && windowWidth <= lastSize
      ) ?? {};

    return activeEl;
  }, [media]);

  const [activeMediaElement, setActiveMediaElement] = useState({});

  useEffect(() => {
    const resizeFunc = () => {
      const activeEl = getMediaElement();

      if (activeEl?.elementBySize === activeMediaElement?.elementBySize) return;

      setActiveMediaElement(activeEl);
    };

    window.addEventListener("resize", resizeFunc);

    resizeFunc();
    return () => window.removeEventListener("resize", resizeFunc);
  }, [activeMediaElement]);

  return activeMediaElement;
};
