import React, { useMemo } from "react";

import { VisitorAvatar } from "./VisitorAvatar";

import { AvatarWithCount } from "../../../../../common/Avatar/AvatarWithCount";
import { useRouter } from "next/router";
import Link from "next/link";

const VisitorsBlock = ({ data, title, subTitle }) => {
  const router = useRouter();
  const { asPath } = router;

  const visitorsList = useMemo(() => {
    const VISIBLE_VISITERS_BY_DEFAULT = 4;
    const visitorsList = [];
    let guestsCount = 0;
    for (const guest of data) {
      if (
        VISIBLE_VISITERS_BY_DEFAULT > visitorsList.length &&
        (guest.avatar ?? false)
      ) {
        visitorsList.push({ Component: VisitorAvatar, ...guest });
        continue;
      }
      guestsCount++;
    }
    if (guestsCount)
      visitorsList.push({
        Component: AvatarWithCount,
        count: guestsCount,
        id: guestsCount,
      });
    return visitorsList;
  }, [data]);

  return (
    <section className="training-visitors">
      <div className="training__wrapper training__wrapper--border ">
        <div className="container">
          <div className="main-title-block training-visitors__title">
            <h2 className="main-title">{title}</h2>

            <Link
              href={`${asPath.split("#")[0]}/visitors`}
              className="training-visitors__button">

              <span>{subTitle}</span>

            </Link>
          </div>
          <div className="avatars-wrapper">
            {visitorsList.map(({ id, Component, ...props }) => (
              <Component {...props} key={id} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VisitorsBlock;
