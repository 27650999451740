import React from "react";
import {
  APPSTORE_SVG,
  GOOGLEPLAY_SVG,
} from "../../../../../constants/svgClubConstants";
import Link from "next/link";
import { SLIDER_PREV_BUTTON_SVG } from "../../../../../constants/svgConstants";

const HeaderAppLinks = ({
  applicationAppStore,
  applicationGooglePlay,
  applicationCallToActionMessage,
}) => {
  return (
    (!!applicationAppStore || !!applicationGooglePlay) && (
      <div className="club-header__link-block">
        {applicationAppStore && (
          <Link
            href={applicationAppStore}
            target="_blank"
            rel="noopener noreferrer"
            className="club-header__link-item club-header__link-item--apple"
          >
            {APPSTORE_SVG}
          </Link>
        )}
        {applicationGooglePlay && (
          <Link
            href={applicationGooglePlay}
            target="_blank"
            rel="noopener noreferrer"
            className="club-header__link-item"
          >
            {GOOGLEPLAY_SVG}
          </Link>
        )}
        {applicationCallToActionMessage && (
          <p className="club-header__link-text">
            <span>{applicationCallToActionMessage}</span>
          </p>
        )}
      </div>
    )
  );
};

export { HeaderAppLinks };
