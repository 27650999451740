import React, { memo } from "react";

import { AlertBlock } from "../../../../../../common/AlertBlock";

import { OptionCardWrapper } from "../OptionCard/OptionCardWrapper";

const OptionAlert = memo(
  ({
    visitId,
    title,
    buttonTitles,
    availableOptions,
    onClose,

    editable,
  }) => {
    const { addTitle, removeTitle } = buttonTitles;

    return (
      <AlertBlock onClose={onClose} initialWidth>
        <>
          <h2 className="sign-up-section__title">{title}</h2>
          {availableOptions.map(({ id, ordered, optionId, ...props }) => (
            <OptionCardWrapper
              {...props}
              ordered={ordered}
              key={id}
              className={"button-wrap"}
              visitId={visitId}
              id={ordered ? optionId : id}
              editable={editable}
              addTitle={addTitle}
              removeTitle={removeTitle}
            />
          ))}
        </>
      </AlertBlock>
    );
  }
);

export { OptionAlert };
