import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import HeaderModalHalls from "./HeaderModalHalls";
import HeaderModalActivity from "./HeaderModalActivity";
import { AlertBlock } from "../../../../common/AlertBlock";
import { useRouter } from "next/router";

function HeaderModal({ onClose, halls, scheduleDefaultView, activities }) {
  const router = useRouter();
  const {
    query: { club },
  } = router;

  const [activeWindow, setActiveWindow] = useState(1);

  const [selectHall, setSelectHall] = useState();
  const [selectActivity, setSelectActivity] = useState();

  useEffect(() => {
    switch (activeWindow) {
      case 1:
        if (halls.length === 1) {
          setSelectHall(halls[0].id);
          setActiveWindow(2);
        }
        break;
      case 2:
        if (activities.length === 0) setActiveWindow(3);
        break;
      case 3:
        const hall = selectHall || "",
          activity =
            !selectActivity || selectActivity === -1 ? 0 : selectActivity;

        router.push({
          pathname: `/club/${club}/schedule/${scheduleDefaultView}/${hall}`,
          query: { ...(activity && { activity }) },
        });
        onClose();

        break;

      default:
        break;
    }
    if (activeWindow !== 1 || halls.length > 1) return;
  }, [activeWindow, scheduleDefaultView]);

  return (
    <AlertBlock onClose={onClose}>
      <>
        {activeWindow === 1 ? (
          <HeaderModalHalls
            halls={halls}
            buttonFunc={(activeItem) => {
              setSelectHall(activeItem);
              setActiveWindow(activeWindow + 1);
            }}
          />
        ) : (
          activeWindow === 2 && (
            <HeaderModalActivity
              activities={activities}
              buttonFunc={(activeItem) => {
                setSelectActivity(activeItem);
                setActiveWindow(activeWindow + 1);
              }}
            />
          )
        )}
      </>
      <style jsx global>{`
        @import "./HeaderModal.scss";
      `}</style>
    </AlertBlock>
  );
}

HeaderModal.propTypes = {
  onClose: PropTypes.func,
  halls: PropTypes.array,
  activities: PropTypes.array,
  matchUrl: PropTypes.string,
};

HeaderModal.defaultProps = {
  onClose: () => {},
  halls: [],
  activities: [],
  matchUrl: null,
};

export default HeaderModal;
