import React from "react";
import { CardButton } from "../../../../../common/CardButton/CardButton";
import { setAuthorization } from "../../../../../../utils/redux/actionCreators/actions";
import { useDispatch } from "react-redux";

import { useRouter } from "next/router";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { ScrollToAnchor } from "../../../../../../hooks";
import { useOnScreen } from "../../../../../../hooks/useOnScreen";

const TrainingBottomNav = ({
  isAuthorized,
  rulesAccepted,
  isActiveTraining,
  refEl,
}) => {
  const router = useRouter();
  const {
    asPath,
    query: { club },
  } = router;
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const nextUrl = `${asPath.split("#")[0]}#payment`;

  const isVisible = useOnScreen(refEl);

  return (
    <>
      {isActiveTraining && !isVisible && (
        <div className="bottom-plug">
          <div className="bottom-plug__content">
            {isAuthorized ? (
              rulesAccepted ? (
                <Link href={nextUrl} className="primary-button">
                  {t("move_to_enroll")}
                </Link>
              ) : (
                <Link
                  href={{ pathname: `/club/${club}/rules`, query: { nextUrl } }}
                  as={`/club/${club}/rules`}
                  replace={true}
                  className="primary-button"
                >
                  {t("sign_up_workout")}
                </Link>
              )
            ) : (
              <CardButton
                className="primary-button"
                onClick={() => dispatch(setAuthorization({ nextUrl }))}
                title={t("sign_up_workout")}
              />
            )}
          </div>
        </div>
      )}
      <ScrollToAnchor />
      <style jsx global>{`
        @import "./TrainingBottomNav.scss";
      `}</style>
    </>
  );
};

export { TrainingBottomNav };
