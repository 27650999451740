import React from "react";
import PropTypes from "prop-types";

import { CancelButton } from "./CancelButton";
import { useTranslation } from "next-i18next";

function CancelItem({
  avatar,
  name,
  paidStatus,
  refundable,
  eventId,
  visitId,
  setCancelTrainingData,
  paid,
  familyId,
  children,
}) {
  const [t] = useTranslation();

  return (
    <div className="training-cancel-block__item">
      <div
        className="training-cancel-block__img"
        {...(avatar && {
          style: {
            backgroundImage: `url(${avatar})`,
          },
        })}
      ></div>

      <div className="training-cancel-block__item-description-block">
        <div className="training-cancel-block__item-description-wrapper">
          <div className="training-cancel-block__item-name-block">
            <p className="training-cancel-block__item-name-desc">
              {t("client")}:
            </p>

            <p className="training-cancel-block__item-name">{name}</p>
          </div>
          <div className="training-cancel-block__item-status-block">
            <div className="training-cancel-block__item-status">
              {paidStatus}
            </div>
            {children}
          </div>
        </div>

        {refundable && (
          <CancelButton
            className="training-cancel-block__item-button"
            title={t("cancel")}
            setCancelTrainingData={setCancelTrainingData}
            familyId={familyId}
            eventId={eventId}
            {...{ paid, visitId }}
          />
        )}
      </div>
    </div>
  );
}

CancelItem.propTypes = {
  avatar: PropTypes.string,
  nameTitleDesc: PropTypes.string,
  name: PropTypes.string,
  paidStatus: PropTypes.string,
  refundable: PropTypes.bool,
  buttonTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
};

CancelItem.defaultProps = {
  avatar: "",
  nameTitleDesc: "",
  name: "",
  paidStatus: "",
  refundable: false,
  buttonTitle: "",
  children: "",
};

export default CancelItem;
