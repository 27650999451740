import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { ArrowsWithNumber } from "../../../../../common/ArrowsWithNumber/ArrowsWithNumber";

import { DROP_SVG } from "../../../../../../constants/svgConstants";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Image from "next/image";

const INSTRUCTORS_PER_PAGE = 5;

function TrainerMobileBlock({
  allInstructors,
  scheduleButton,
  hallsetId,
  isScheduleLink,
  isDescription = false,
  isClubMain = false,
  pathbefore,
}) {
  const router = useRouter();
  const {
    query: { club },
  } = router;

  const [t] = useTranslation();

  const lengthOfPages = useMemo(
    () => Math.ceil(allInstructors.length / INSTRUCTORS_PER_PAGE),
    [allInstructors, INSTRUCTORS_PER_PAGE]
  );
  const [numberOfPage, setNumberOfPage] = useState(1);
  const onPrev = useCallback(() => {
    if (numberOfPage <= 1) return;

    setNumberOfPage(numberOfPage - 1);
  }, [numberOfPage]);

  const onNext = useCallback(() => {
    if (numberOfPage >= lengthOfPages) return;

    setNumberOfPage(numberOfPage + 1);
  }, [lengthOfPages, numberOfPage]);

  const instructorsData = useMemo(
    () =>
      allInstructors.slice(
        (numberOfPage - 1) * INSTRUCTORS_PER_PAGE,
        numberOfPage * INSTRUCTORS_PER_PAGE
      ),
    [numberOfPage, INSTRUCTORS_PER_PAGE, allInstructors]
  );

  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="club-trainers-mob">
        {lengthOfPages > 1 && (
          <div className="club-trainers-mob__arrows-block">
            <ArrowsWithNumber
              numberOfPage={numberOfPage}
              lengthOfPages={lengthOfPages}
              onPrev={onPrev}
              onNext={onNext}
            />
          </div>
        )}

        {instructorsData.map((props) => {
          const { id, name, instructorDescription, instructorImage } = props;

          const maxDescrLength = 300;
          let descrLength = 0;
          const shortDescription =
            instructorDescription &&
            instructorDescription
              .split(".")
              .filter((sentence) => {
                const lengthSentence = sentence.length;

                if (descrLength + lengthSentence <= maxDescrLength) {
                  descrLength += lengthSentence;

                  return true;
                } else if (descrLength < maxDescrLength) {
                  descrLength += maxDescrLength;
                }

                return false;
              })
              .join(".");
          const isFullDescription = maxDescrLength >= descrLength;

          return (
            // <div >
            // </div>
            <Link
              href={{
                pathname: `/club/${club}/instructor/${id}`,
                query: { hallsetId, fromClub: isClubMain, pathbefore },
              }}
              as={`/club/${club}/instructor/${id}`}
              key={id}
              className="club-trainers-mob__item"
            >
              <>
                <div className="club-trainers-mob__item-wrapper">
                  <div className="club-trainers-mob__wrapper-img">
                    {instructorImage && (
                      <Image
                        className="club-trainers-mob__img"
                        src={instructorImage}
                        fill
                        alt={`instructor${id}`}
                        quality={"100"}
                      />
                    )}
                  </div>

                  <div className="club-trainers-mob__description-block">
                    <div className="club-trainers-mob__item-title-wrapper">
                      <p className="club-trainers-mob__item-title">
                        {name}
                      </p>
                    </div>

                    {isScheduleLink && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          router.push(
                            {
                              pathname: `${club}/schedule/list/${hallsetId}`,
                              query: {
                                instructor: id,
                              },
                            },
                            `${club}/schedule/list/${hallsetId}`
                          );
                        }}
                        className="club-trainers-mob__item-button"
                      >
                        {scheduleButton}
                      </button>
                    )}
                  </div>
                </div>
                {instructorDescription && isDescription && (
                  <div className="club-trainers-mob__item-description">
                    <div
                      className={
                        showMore
                          ? "club-trainers-mob__item-description-wrapper"
                          : "club-trainers-mob__item-description-wrapper_hidden"
                      }
                      dangerouslySetInnerHTML={{
                        __html:
                          !!isFullDescription || !!showMore
                            ? instructorDescription
                            : `${shortDescription}...`,
                      }}
                    ></div>

                    {!isFullDescription && (
                      <button
                        className={
                          showMore
                            ? "landing-alert__less-button"
                            : "landing-alert__more-button"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setShowMore(!showMore);
                        }}
                      >
                        {t(showMore ? "hide" : "more")} {DROP_SVG}
                      </button>
                    )}
                  </div>
                )}
              </>
            </Link>
          );
        })}
      </div>
      <style jsx global>{`
        @import "./TrainerMobileBlock.scss?7";
      `}</style>
    </>
  );
}
TrainerMobileBlock.propTypes = {
  matchUrl: PropTypes.string,
  allInstructors: PropTypes.array,
  scheduleButton: PropTypes.string,
  hallsetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isScheduleLink: PropTypes.bool,
  isDescription: PropTypes.bool,
};

TrainerMobileBlock.defaultProps = {
  matchUrl: "",
  allInstructors: [],
  scheduleButton: "",
  hallsetId: null,
  isScheduleLink: true,
  isDescription: false,
};

export default TrainerMobileBlock;
