import React from 'react';
import { AvatarPlaceholder } from './AvatarPlaceholder';

export const AvatarWithCount = ({ count }) => {
  return (
    <div className="avatar-wrapper avatar__visitor">
      <AvatarPlaceholder />
      {count > 1 && <div className="avatar-count">{count}</div>}
    </div>
  );
};
