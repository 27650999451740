import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  TIME_TITLE,
  TIME_MINUTES_TITLE,
} from "../../ToggleBlockOfData/ScheduleWeek/languageConstants";

import DesctopBlock from "./DesctopBlock";
import MobileBlock from "./MobileBlock";
import { useMediaSize } from "../../../routePages/ScheduleSection/useMediaSize";

import { useTranslation } from "next-i18next";
import { useQuery } from "@apollo/client";
import { GET_CLUB_HEADER_DATA } from "../../../../../../graphql/queries/queriesClub";

const MEDIA_SIZES = [
  { firstSize: 0, lastSize: 550, elementBySize: "mobile" },
  { firstSize: 550, lastSize: +Infinity, elementBySize: "desctop" },
];
function TrainingAboutBlock({
  duration,
  description,
  descriptionHtml,
  dateTime,
  priceInfo,
  activityTitle,
  title,
}) {
  const [t, { language }] = useTranslation();

  const { elementBySize } = useMediaSize(MEDIA_SIZES);
  const { data: { club: { currencyTitle } } = {} } = useQuery(
    GET_CLUB_HEADER_DATA,
    { variables: { showOffer: false } }
  );

  const timeTitle = useMemo(() => {
    const hours = Math.floor(duration / 60),
      date = !duration
        ? ""
        : duration < 60
        ? `${duration} ${t(TIME_MINUTES_TITLE(duration))}`
        : duration % 60 === 0
        ? `${hours} ${t(TIME_TITLE(hours))}`
        : `${hours} ${t(TIME_TITLE(hours))} 
      ${duration % 60} ${t(TIME_MINUTES_TITLE(duration % 60))}`;

    return date;
  }, [duration, language]);

  const priceTitle = useMemo(() => {
    if (!priceInfo) return priceInfo;
    const { regularPrice, price, bonusTitle } = priceInfo;

    if (+price === +regularPrice) return `${regularPrice} ${currencyTitle}`;

    return (
      <>
        <span className="about-info__inactive">{`${+regularPrice} ${currencyTitle}`}</span>
        {price}
        <span className="about-info--default-mont"> {currencyTitle}</span>
        {bonusTitle && (
          <>
            <span className="about-info--disabled">/</span>
            <span className="about-info--default-lato">
              {bonusTitle.toLowerCase()}
            </span>
          </>
        )}
      </>
    );
  }, [priceInfo, currencyTitle]);

  return (
    <section className="training-about">
      <div className="container">
        {elementBySize === "mobile" ? (
          <MobileBlock
            descriptionHtml={descriptionHtml}
            description={description}
            dateTime={dateTime}
            timeTitle={timeTitle}
            priceTitle={priceTitle}
            activityTitle={activityTitle}
            title={title}
          />
        ) : (
          <DesctopBlock
            descriptionHtml={descriptionHtml}
            description={description}
            dateTime={dateTime}
            timeTitle={timeTitle}
            priceTitle={priceTitle}
          />
        )}
      </div>
      <style jsx global>{`
        @import "./TrainingAboutBlock.scss?5";
        @import "../../PerspectiveCard/CardMedia.scss";
      `}</style>
    </section>
  );
}

TrainingAboutBlock.propTypes = {
  duration: PropTypes.number,

  price: PropTypes.string,
  regularPrice: PropTypes.number,
  description: PropTypes.string,
  descriptionHtml: PropTypes.bool,
  dateTime: PropTypes.string,

  activityTitle: PropTypes.string,
  title: PropTypes.string,
};
TrainingAboutBlock.defaultProps = {
  duration: null,

  price: "",
  regularPrice: null,
  description: "",
  descriptionHtml: false,
  dateTime: "",

  activityTitle: "",
  title: "",
};

export default TrainingAboutBlock;
