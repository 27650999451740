import { useEffect, useState, useMemo } from "react";

function useScrollEvent({ activeBlockOfContent, isRequested, data = [] }) {
  const [{ hasRichedMax, isLoading, page }, setState] = useState({
    isLoading: false,
    page: 1,
    hasRichedMax: false,
    data,
  });

  const { addPage, successLoaded, finishLoading, reset } = useMemo(() => {
    return {
      addPage: () =>
        setState((s) => ({
          ...s,
          isLoading: true,
          page: s.page + 1,
        })),
      successLoaded: () =>
        setState((s) => ({
          ...s,
          isLoading: false,
          hasRichedMax: false,
        })),
      finishLoading: () =>
        setState((s) => ({
          ...s,
          isLoading: false,
          hasRichedMax: true,
        })),
      reset: () =>
        setState({ data: [], isLoading: false, page: 1, hasRichedMax: false }),
    };
  }, []);

  useEffect(() => {
    if (
      isRequested ||
      activeBlockOfContent != "list" ||
      isLoading ||
      hasRichedMax
    )
      return () => window.removeEventListener("scroll", scrollFunc);

    const scrollFunc = () => {
      const scrollHeight = window.document.body.scrollHeight;
      const topHeight = window.innerHeight + window.pageYOffset;
      const windowHeight = window.innerHeight;

      if (scrollHeight - 100 > topHeight || windowHeight + 100 >= topHeight)
        return;

      addPage();
    };

    window.addEventListener("scroll", scrollFunc);

    return () => window.removeEventListener("scroll", scrollFunc);
  }, [hasRichedMax, isLoading, addPage, activeBlockOfContent, isRequested]);

  return { successLoaded, finishLoading, reset, page };
}

export default useScrollEvent;
