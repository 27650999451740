const DAY_IN_MILLISECONDS = 1000 * 3600 * 24;

export function getDaysInMonth(date: Date): number {
  return endOfMonth(date).getDate();
}

const fixDate = (dateString: string) => dateString.replace(/-/g, "/");

export const startOfYearMonth = (date: Date): Date =>
  new Date(`${date.getFullYear()}-01-01`);

export const startOfMonth = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), 1);

export const endOfMonth = (date: Date): Date => {
  // const currentMonth = date.getMonth();
  // return new Date(
  //   new Date(new Date(date).setMonth(currentMonth + 1)).setDate(-1)
  // );
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // return new Date(`${date.getFullYear()}-${date.getMonth()}-01`);
};

export function getFirstDayOfWeek(date: Date): Date {
  //isoweek
  const d = new Date(date);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export function diffInDays(date1: Date, date2: Date): number {
  return (date1.getTime() - date2.getTime()) / DAY_IN_MILLISECONDS;
}

export const getDateOfMonth = (date: Date): number => date.getDate();

export const formatBySymbol = (
  inputDate: Date,
  symbol: string = "."
): string => {
  let date, month, year;

  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  date = date.toString().padStart(2, "0");

  month = month.toString().padStart(2, "0");

  return `${date}${symbol}${month}${symbol}${year}`;
};
