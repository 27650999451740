import Image from "next/image";
import React from "react";
import nouser from "../../../../public/nouser.png";

export const AvatarPlaceholder = ({ avatar }) => {
  return (
    <Image
      src={avatar ?? nouser.src}
      className="avatar__placeholder avatar"
      fill
      alt="user-avatar"
    />
  );
};
