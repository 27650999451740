import React from "react";

import { AvatarPlaceholder } from "../../../../../common/Avatar/AvatarPlaceholder";

export const VisitorAvatar = ({ avatar }) => {
  return (
    <div className="avatar-wrapper avatar__visitor">
      <AvatarPlaceholder avatar={avatar} />
    </div>
  );
};
