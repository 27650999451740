import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { BackButton } from "../../../../../common/BackButton/BackButton";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// import { TAXI_SVG, MAP_SVG } from "../../../../../../constants/svgConstants";

function TrainingHeaderBlock({ image, title, address, nameHall }) {
  const [t] = useTranslation();
  const router = useRouter();
  const { back, components, pathname } = router;

  const { isInitialPage, onBack } = useMemo(() => {
    const previousRoute = components[`/club/[club]/schedule/[...typeSlug]`];
    const isInitialPage = components
      ? Object.entries(components).some(
          ([key, path]) => key.includes(pathname) && path.initial
        )
        ? true
        : !!previousRoute
          ? false
          : true
      : true;

    const previousVisitors =
      components[`/club/[club]/event/[eventId]/visitors`];

    const onBack =
      // !isInitialPage &&
      previousVisitors && previousRoute
        ? () =>
            router.replace(
              {
                pathname: previousRoute.route,
                query: previousRoute.query,
              },
              previousRoute.resolvedAs
            )
        : back;

    return { isInitialPage, onBack };
  }, [components]);

  return (
    <section className="training-header-container container">
      {!isInitialPage && (
        <BackButton
          title={t("go_back")}
          onClick={onBack}
          additionalClass={"training-header__back-button"}
        />
      )}
      <div className="training-header">
        {image && (
          <Image
            className="training-header__img"
            src={image}
            fill
            alt="event-image"
            blurDataURL={image}
            placeholder="blur"
            priority={true}
          />
        )}

        <div className="training-header__wrapper container-header">
          <div className="training-header__inner">
            <div className="training-header__content">
              <h1 className="training-header__title training-header-title">
                {title}
              </h1>
              <div className="training-header__subtitle-block">
                <div className="training-header__name-hallset">{nameHall}</div>
                <div className="training-header__address">{address}</div>
              </div>
              {/* <div className="club-active-hall__buttons-block training-header-buttons-block">
              <div className="club-active-hall__button">{MAP_SVG}</div>
              <div className="club-active-hall__button">{TAXI_SVG}</div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        @import "./TrainingHeaderBlock.scss?5";
      `}</style>
    </section>
  );
}

TrainingHeaderBlock.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  nameHall: PropTypes.string,
};
TrainingHeaderBlock.defaultProps = {
  image: "",
  title: "",
  address: "",
  nameHall: "",
};

export default TrainingHeaderBlock;
