import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { CardButton } from "../../../../../common/CardButton/CardButton";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setAuthorization } from "../../../../../../utils/redux/actionCreators/actions";
import { REQUEST_VISIT } from "../../../../../../graphql/mutations/mutationEvent";
import { useToggle } from "../../../../../../hooks";
import { useMutation } from "@apollo/client";

function LeadButtonsBlock({
  isAuthorized,
  isActiveTraining = false,
  // setLeadModal,
  onRequest,
  showLeadButton,
  status,
  rulesAccepted = false,
}) {
  const [t] = useTranslation();
  const router = useRouter();

  const dispatch = useDispatch();
  const {
    query: { club, eventId },
    asPath,
  } = router;

  const showRules = !rulesAccepted;

  const [leadModal, toggleModal] = useToggle(false);

  const [requestVisit] = useMutation(REQUEST_VISIT, {
    refetchQueries: [`ClientEventDetail`],
  });

  useEffect(() => {
    if (!eventId || !leadModal) return;
    requestVisit({
      variables: { eventId: +eventId, pilot: true },
    })
      .then(
        () => {
          onRequest({
            isSuccess: true,
            message: t("enroll_successfully_submitted"),
          });
        },
        ({ graphQLErrors, message }) => {
          if (graphQLErrors?.[0]?.result === 5) return;
          onRequest({ isSuccess: false, message });
        }
      )
      .finally(toggleModal);
  }, [eventId, leadModal]);

  return (
    <>
      {status && (
        <div className="training-about__status-block">
          <p className="training-about__status-title">{status}</p>
        </div>
      )}
      {isActiveTraining && (!!showLeadButton || !!showRules) && (
        <div className="training-about__status-block">
          {showLeadButton && (
            <CardButton
              className="training-about__status-cancel-button"
              onClick={() => {
                isAuthorized ? toggleModal() : dispatch(setAuthorization({ setHeader: "lead" }));
              }}
              title={t("trial_workout")}
            />
          )}
          {showRules && (
            <CardButton
              className="training-about__status-confirm-button"
              onClick={() =>
                isAuthorized
                  ? router.replace(
                      {
                        pathname: `/club/${club}/rules`,
                        query: { nextUrl: asPath },
                      },
                      `/club/${club}/rules`
                    )
                  : dispatch(setAuthorization({ nextUrl: asPath }))
              }
              title={t("sign_up_workout")}
            />
          )}
        </div>
      )}
    </>
  );
}

LeadButtonsBlock.propTypes = {
  status: PropTypes.string,

  extraStyles: PropTypes.object,
  showLeadButton: PropTypes.bool,
};

LeadButtonsBlock.defaultProps = {
  status: "",

  extraStyles: {},
  showLeadButton: false,
};

export default LeadButtonsBlock;
