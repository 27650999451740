import React, { useState, useEffect } from "react";

import ModalDropdownId from "../../../../common/ModalDropdown/ModalDropdownId";
import { useTranslation } from 'next-i18next';

function HeaderModalHalls({ halls, buttonFunc }) {
  const [t] = useTranslation();
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    if (!halls?.length) return;

    setActiveId(halls[0].id);
  }, [halls]);

  return (
    <>
      <ModalDropdownId
        items={halls}
        activeId={activeId}
        setActiveId={setActiveId}
      />

      <button
        className="header-modal__button"
        onClick={() => buttonFunc(activeId)}
      >
        {t('next')}
      </button>
    </>
  );
}

export default HeaderModalHalls;
