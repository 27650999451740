import React, { useState, useRef, useCallback, useEffect } from "react";

import { CircularProgressIndicator } from "../CircularProgressIndicator/CircularProgressIndicator";

const LazyElement = ({ className, root, rootMargin, threshold, children }) => {
  const [isVisibleMap, setVisibleMap] = useState(false);
  const ref = useRef(null);

  const callback = useCallback((entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) setVisibleMap(true);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callback, {
      root,
      rootMargin,
      threshold,
    });
    const timer = setTimeout(() => {
      observer.observe(ref.current);
    }, 2000);

    return () => {
      observer.disconnect();
      clearTimeout(timer);
    };
  }, [callback, root, rootMargin, threshold, ref]);

  return (
    <div ref={ref} className={className}>
      {isVisibleMap ? children : <CircularProgressIndicator />}
    </div>
  );
};

LazyElement.defaultProps = {
  root: null,
  rootMargin: "0px",
  threshold: 0.01,
};

export { LazyElement };
