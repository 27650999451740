import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import Header from "../../components/Header/Header";
import { DescriptionSection } from "../../components/DescriptionSection/DescriptionSection";

import { LazyElement } from "../../../../common/LazyElement";

import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { GET_CLUB_ACTIVITIES } from "../../../../../graphql/queries/queriesClub";
import { useLazyQuery } from "@apollo/client";

const Activities = dynamic(
  () => import("../../components/Activities/Activities")
);

const HallTrainerSection = dynamic(
  () => import("../../components/HallTrainerSection/HallTrainerSection")
);

const SubscriptionSection = dynamic(
  () => import("../../components/SubscriptionSection/SubscriptionSection")
);

const AdvantagesSection = dynamic(
  () => import("../../components/AdvantagesSection/AdvantagesSection")
);

const AnnounceSection = dynamic(
  () => import("../../components/AnnounceSection/AnnounceSection")
);

const BlogSection = dynamic(
  () => import("../../components/BlogSection/BlogSection")
);

const MapSection = dynamic(
  () => import("../../components/MapSection/MapSection")
);

function ClubPage({
  title,
  titleShort,
  image,
  icon,
  description,
  descriptionHtml,
  descriptionHeader,
  halls,
  portal: { slug: portalSlug, clubs } = {},
  clubColors: { primaryTextColor } = {},
  city: coords,
  imageTitleEnabled,
  advantages,
  contactFacebook,
  contactInstagram,
  contactYoutube,
  contactTelegram,
  contactLinkedin,
  contactViber,
  contactTiktok,
  // pilotEnabled,
  hallShowEnabled,
  hallMapEnabled,
  // storeEnabled,
  cardOpenPriceEnabled,
  applicationGooglePlay,
  applicationAppStore,
  applicationCallToActionMessage,
}) {
  const {
    i18n: { language },
  } = useTranslation();

  const mapObject = useMemo(
    () => ({ titleShort, icon, halls }),
    [titleShort, icon, halls]
  );

  const [getActivities] = useLazyQuery(GET_CLUB_ACTIVITIES, {
    fetchPolicy: "cache-and-network",
    variables: { language },
    nextFetchPolicy: "cache-first",
  });

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    getActivities({
      onCompleted: ({ club = {} }) => {
        const { activities = [] } = club;

        const sorted = [...activities].sort(
          ({ order: a }, { order: b }) => a - b
        );

        setActivities(sorted);
      },
      onError: () => {
        setActivities([]);
      },
    });
  }, [language]);

  const hasPortal = clubs?.length > 1;

  return (
    <>
      <div className="club-page">
        <Header
          title={title}
          image={image}
          activities={activities}
          halls={halls}
          imageTitleEnabled={imageTitleEnabled}
          hasPortal={hasPortal}
          portalSlug={portalSlug}
          applicationGooglePlay={applicationGooglePlay}
          applicationAppStore={applicationAppStore}
          applicationCallToActionMessage={applicationCallToActionMessage}
        />

        {!!description && (
          <DescriptionSection
            description={description}
            descriptionHeader={descriptionHeader}
            descriptionHtml={descriptionHtml}
          />
        )}

        {!!advantages?.length && <AdvantagesSection advantages={advantages} />}

        {!!activities?.length && <Activities activities={activities} />}

        {hallShowEnabled && !!halls?.length && (
          <HallTrainerSection icon={icon} halls={halls} />
        )}

        {cardOpenPriceEnabled && <SubscriptionSection />}

        {/* {storeEnabled && <ShopSection />} */}

        <AnnounceSection />

        <BlogSection color={primaryTextColor} />

        {hallMapEnabled && (
          <LazyElement className={"club-page--background"}>
            <MapSection
              club={mapObject}
              latitude={coords.latitude}
              longitude={coords.longitude}
              contactFacebook={contactFacebook}
              contactInstagram={contactInstagram}
              contactYoutube={contactYoutube}
              contactTelegram={contactTelegram}
              contactLinkedin={contactLinkedin}
              contactViber={contactViber}
              contactTiktok={contactTiktok}
            />
          </LazyElement>
        )}
      </div>

      <style jsx global>{`
        @import "./ClubPage.scss?3s";
      `}</style>
    </>
  );
}

ClubPage.propTypes = {
  title: PropTypes.string,
  titleShort: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.string,
  descriptionHeader: PropTypes.string,
  descriptionHtml: PropTypes.bool,
  activeClub: PropTypes.string,
  halls: PropTypes.array,
  activities: PropTypes.array,
  isHistoryPush: PropTypes.bool,
  clubColors: PropTypes.object,
  navActiveSectionLink: PropTypes.string,
  coords: PropTypes.object,
  matchUrl: PropTypes.string,
  advantages: PropTypes.array,
  contactFacebook: PropTypes.string,
  contactInstagram: PropTypes.string,
  contactYoutube: PropTypes.string,
  contactTelegram: PropTypes.string,
  contactLinkedin: PropTypes.string,
  pilotEnabled: PropTypes.bool,
};

ClubPage.defaultProps = {
  title: "",
  titleShort: "",
  image: "",
  icon: "",
  description: "",
  descriptionHeader: "",
  descriptionHtml: false,
  activeClub: "",
  halls: [],
  activities: [],
  isHistoryPush: false,
  clubColors: {},
  navActiveSectionLink: null,
  coords: {},
  matchUrl: "",
  advantages: [],
  contactFacebook: "",
  contactInstagram: "",
  contactYoutube: "",
  contactTelegram: "",
  contactLinkedin: "",
  pilotEnabled: false,
};

export default ClubPage;
