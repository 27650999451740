import { useEffect } from "react";

import { TOGGLE_SCHEDULE_BUTTONS } from "../../components/ScheduleBlock/languageConstants";

import { useRouter } from "next/router";

export const useTypeSwitcher = ({
  scheduleDefaultView,
  setActive,
  elementBySize,
}) => {
  const router = useRouter();

  const {
    query: {
      club,
      typeSlug: [type],
    },
  } = router;

  useEffect(() => {
    if (!type) return;

    const available = TOGGLE_SCHEDULE_BUTTONS.find(({ slug }) => slug === type);

    const toDefaultView = () => {
      if (type !== scheduleDefaultView)
        router.replace(
          {
            pathname: `/club/${club}/schedule/${scheduleDefaultView}`,
          },
          undefined,
          { shallow: true }
        );
    };

    if (elementBySize) {
      const availableMobile = TOGGLE_SCHEDULE_BUTTONS.find(
        ({ slug, onlyDesctop }) => slug === type && !onlyDesctop
      );

      if (availableMobile) return setActive(type);
      if (available) return setActive("day");

      return toDefaultView();
    }

    if (available) return setActive(type);

    return toDefaultView();
  }, [type, elementBySize, scheduleDefaultView]);

  return null;
};
