import React, { memo, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import Swiper from "react-id-swiper";

import {
  SLIDER_NEXT_BUTTON_SVG,
  SLIDER_PREV_BUTTON_SVG,
} from "../../../../../constants/svgConstants";

{
  /* ДЛЯ ЭКРАНОВ ПК И ПЛАНШЕТОВ */
}
const SwiperBlock = memo(({ params = {}, children, isArrows }) => {
  const swiperEl = useRef(null);

  const { goPrev, goNext } = useMemo(() => {
    return {
      goNext: () => {
        const swiper = swiperEl?.current?.swiper;
        swiper?.slideNext();
      },
      goPrev: () => {
        const swiper = swiperEl?.current?.swiper;
        swiper?.slidePrev();
      },
    };
  }, []);

  return (
    <>
      {children.length > 1 ? (
        <>
          {isArrows && (
            <>
              <div
                onClick={goPrev}
                className={
                  "swiper-btn-arrow-prev btn-arrow trainer-block-swiper-prev-btn"
                }
              >
                {SLIDER_PREV_BUTTON_SVG()}
              </div>
              <div
                onClick={goNext}
                className={
                  "swiper-btn-arrow-next btn-arrow trainer-block-swiper-next-btn"
                }
              >
                {SLIDER_NEXT_BUTTON_SVG()}
              </div>
            </>
          )}
          <Swiper {...params} shouldSwiperUpdate={true} ref={swiperEl}>
            {children}
          </Swiper>
        </>
      ) : (
        children
      )}
    </>
  );
});

SwiperBlock.propTypes = {
  children: PropTypes.node.isRequired,
  isArrows: PropTypes.bool,
};

SwiperBlock.defaultProps = {
  children: null,
  isArrows: true,
};

export { SwiperBlock };
