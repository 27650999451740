import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import { DROP_SVG } from "../../../constants/svgConstants";

function ModalDropdownId({
  items,
  activeId,
  setActiveId,
  extraStyles,
  defaultTitleId,
  defaultTitle,
  isSecondTitle,
}) {
  const [openDropdown, setOpenDropdown] = useState(false);

  const { activeTitle, activeSubtitle } = useMemo(() => {
    const activeElem = items.find(({ id }) => id == activeId);
    const defaultElem = items.find(({ id }) => id == defaultTitleId);

    if (!activeElem && defaultTitle) {
      return { activeTitle: defaultTitle };
    } else if (!activeElem && !defaultTitle) return { activeTitle: "" };

    if (activeElem.id === defaultTitleId)
      return { activeTitle: defaultElem.secondTitle };

    return {
      activeTitle: activeElem.title,
      activeSubtitle: activeElem.subtitle,
    };
  }, [activeId, items, defaultTitleId, defaultTitle]);

  return (
    <div className="modal-dropdown" style={{ ...extraStyles }}>
      <div
        className={`modal-dropdown__button ${
          openDropdown ? "modal-dropdown__button_active" : ""
        }`}
        onClick={() => {
          setOpenDropdown(!openDropdown);
        }}
      >
        <div>
          {activeTitle}
          {!!isSecondTitle && !!activeSubtitle && (
            <span className="modal-dropdown__button_subtitle">
              {activeSubtitle}
            </span>
          )}
        </div>
        {DROP_SVG}
      </div>

      {openDropdown && (
        <div className="modal-dropdown__wrapper">
          <div className="modal-dropdown__inner">
            {items
              .filter(({ id }) => id !== activeId)
              .map(({ id, title, subtitle }) => (
                <div
                  className="modal-dropdown__item"
                  key={id}
                  onClick={() => {
                    setOpenDropdown(false);
                    setActiveId(id);
                  }}
                >
                  {title}
                  {!!isSecondTitle && !!subtitle && (
                    <span className="modal-dropdown__button_subtitle">
                      {subtitle}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
      <style jsx global>{`
        @import "./ModalDropdown.scss?";
      `}</style>
    </div>
  );
}

ModalDropdownId.propTypes = {
  items: PropTypes.array,
  activeItem: PropTypes.object,
  setActiveItem: PropTypes.func,
  extraStyles: PropTypes.object,
  defaultTitleId: PropTypes.number,
  defaultTitle: PropTypes.string,
  isSecondTitle: PropTypes.bool,
};

ModalDropdownId.defaultProps = {
  items: [],
  activeItem: {},
  setActiveItem: () => {},
  extraStyles: {},
  defaultTitleId: -1,
  defaultTitle: "",
  isSecondTitle: false,
};

export default ModalDropdownId;
