import React, { memo } from "react";

export const DescriptionSection = memo(
  ({ description, descriptionHtml, descriptionHeader }) => {
    return (
      <section className="club-description club-page--background">
        <div className="container">
          {!!descriptionHeader && (
            <h2 className="club-page-title">{descriptionHeader}</h2>
          )}

          <div className="club-description__inner">
            {descriptionHtml ? (
              <div
                className="club-description__text description-html"
                dangerouslySetInnerHTML={{ __html: description }}
              ></div>
            ) : (
              <div className="club-description__text description-html">
                {description}
              </div>
            )}
          </div>
        </div>
        <style jsx global>{`
          @import "./DescriptionSection.scss?1";
        `}</style>
      </section>
    );
  }
);
