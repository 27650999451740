import React, { useMemo, useState, useEffect } from "react";

import { useEventsByHall } from "./useEventsByHall";

import ClubSearchSwiper from "../../components/ClubSearchSwiper";
import { ScheduleBlock } from "../../components/ScheduleBlock";

import { TOGGLE_SCHEDULE_BUTTONS } from "../../components/ScheduleBlock/languageConstants";

import { useMediaSize } from "./useMediaSize";
import { useTypeSwitcher } from "./useTypeSwitcher";
import useScheduleNavigation from "./useScheduleNavigation";

import { HocWithPreloader } from "../../../../../hocs/hocWithPreloader";
import { useLazyQuery } from "@apollo/client";
import { GET_CLUB_BY_ACTIVITY } from "../../../../../graphql/queries/queriesClub";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { useAuth } from "../../../../../utils/auth";
import { convertDayToShortName } from "../../components/ToggleBlockOfData/calendarUtil";

function ScheduleSection({ scheduleDefaultView, dayStartTime, eveningStartTime }) {
  const router = useRouter();
  const [t, { language }] = useTranslation();

  const { accessToken } = useAuth();

  const { query, asPath } = router;

  const {
    club,
    typeSlug: [type, hallId],
    activity = 0,
    instructor = 0,
    template = 0,
    zone = 0,
    yearNav: year,
    monthNav: month,
    weekNav: week,
    day,
    hallPhone,
    time = "",
  } = query;

  const [{ halls, loading }, setHalls] = useState({ halls: [], loading: true });

  const { setActivity, setInstructor, setTemplate, setZone, changeActiveId } = useMemo(() => {
    const pathname = asPath.split("?")[0];

    const defaultOptions = {
      yearNav: year,
      monthNav: month,
      weekNav: week,
      day,
      hallPhone,
      time,
    };

    return {
      setActivity: (activity) => {
        router.replace(
          {
            pathname,
            query: {
              instructor,
              zone,
              activity,
              template,
              ...defaultOptions,
            },
          },
          pathname,
          { shallow: true }
        );
      },
      setTemplate: (template) => {
        router.replace(
          {
            pathname,
            query: {
              instructor,
              zone,
              activity,
              template,
              ...defaultOptions,
            },
          },
          pathname,
          { shallow: true }
        );
      },
      setInstructor: (instructor) => {
        router.replace(
          {
            pathname,
            query: {
              zone,
              activity,
              instructor,
              template,
              ...defaultOptions,
            },
          },
          pathname,
          { shallow: true }
        );
      },
      setZone: (defZone) => {
        router.replace(
          {
            pathname,
            query: {
              instructor,
              activity,
              template,
              zone: defZone == zone ? 0 : defZone,
              ...defaultOptions,
            },
          },
          pathname,
          { shallow: true }
        );
      },
      changeActiveId: (id, hallPhone) => {
        const pathname = `/club/${club}/schedule/${type}/${id}`;

        router.push(
          {
            pathname,
            query: {
              instructor,
              activity,
              template,
              zone: 0,
              ...defaultOptions,
              hallPhone,
            },
          },
          pathname,
          { shallow: true }
        );
      },
    };
  }, [asPath, template, instructor, zone, activity, year, month, week, day, hallPhone, time]);

  const [toggleButtons, setToggleButtons] = useState({
    isMorningTime: false,
    isAfternoonTime: false,
    isEveningTime: false,
    isMyVisit: false,
  });

  // Все локации по клубу

  const [getHalls] = useLazyQuery(GET_CLUB_BY_ACTIVITY, {
    fetchPolicy: "cache-and-network",
    variables: { language },
  });

  useEffect(() => {
    const check = () =>
      getHalls({
        variables: { activity: +activity },
        onCompleted: ({ club = {} }) => {
          const { halls = [] } = club;

          setHalls({ loading: false, halls });
        },
        onError: () => {
          setHalls((s) => ({ ...s, loading: false }));
        },
      });
    check();
  }, [activity, accessToken]);

  const { activities, instructors, zones, templates, timeClose } = useMemo(() => {
    if (!halls?.length || !hallId) return { activities: [], instructors: [], zones: [], templates: [] };

    const {
      activities,
      instructors: hallInstructors,
      zones,
      templates,
      timeClose,
    } = halls.find(({ id }) => id == hallId) || {
      activities: [],
      instructors: [],
      zones: [],
      templates: [],
    };

    const instructors = hallInstructors.filter(({ isInstructorVisible }) => isInstructorVisible);

    return { activities, instructors, zones, templates, timeClose };
  }, [halls, hallId]);

  useEffect(() => {
    if (!halls?.length) return;

    const check = () => {
      const currentHall = halls.find(({ id }) => hallId && +id === +hallId);
      const { activity = 0, instructor = 0, template = 0, yearNav, monthNav, weekNav, day, time } = query;

      const defaultOptions = {
        activity,
        instructor,
        template,
        ...(monthNav && { monthNav }),
        ...(weekNav && { weekNav }),
        ...(yearNav && { yearNav }),
        ...(day && { day }),
        time,
      };

      if (!currentHall) {
        const { zones, phone } = halls[0];
        const pathname = `/club/${club}/schedule/${type}/${halls[0].id}`;

        return router.replace(
          {
            pathname,
            query: {
              ...defaultOptions,
              ...(zones?.length === 1 && { zone: zones[0].id }),
              hallPhone: phone,
            },
          },
          pathname,
          { shallow: true }
        );
      }

      const { zones, phone } = currentHall;

      if (zones?.length === 1) {
        const pathname = `/club/${club}/schedule/${type}/${hallId}`;
        router.replace(
          {
            pathname,
            query: {
              ...defaultOptions,
              zone: zones[0].id,
              hallPhone: phone,
            },
          },
          pathname,
          { shallow: true }
        );
      }
    };

    check();
  }, [halls]);

  // Вид рассписания
  const [activeBlockOfContent, setActiveBlockOfContent] = useState(() => {
    const available = TOGGLE_SCHEDULE_BUTTONS.find(({ slug }) => slug === type);
    if (available) return type;
    return TOGGLE_SCHEDULE_BUTTONS[1].slug;
  });
  const { elementBySize } = useMediaSize();

  //  при изменении размера экрана меняем активнй блок на day
  useTypeSwitcher({
    setActive: setActiveBlockOfContent,
    elementBySize,
    scheduleDefaultView,
  });

  // активная дата - день неделя месяц в блоке данных
  const { nav, setSelectedDay, setWeekNav, setMonthNav, setDayNav } = useScheduleNavigation();

  // Подгружаем все ивенты с учетом выбраного блока и активной даты
  const {
    allEvents = [],
    queryOptions = {},
    setEvents,
    showRequestBlock = true,
  } = useEventsByHall({
    hallId,
    activeBlockOfContent,
    nav,
    activity,
    instructor,
    template,
    time,
    zone,
    toggleButtons,
  });

  useEffect(() => {
    if (!showRequestBlock) return;
    const { yearNav, monthNav, weekNav, dayNav } = nav;

    const yearNavNum = +yearNav;
    const monthNavNum = +monthNav;
    const weekNavNum = +weekNav;
    // const dayNavNum = +dayNav;

    if (
      !yearNav ||
      !(Number(monthNav) >= 0) ||
      !(Number(weekNav) >= 0) ||
      // !(Number(dayNav) >= 0)
      !dayNav
    )
      return;
    const dayByName = convertDayToShortName(dayNav);
    if (yearNavNum === +year && monthNavNum === +month && weekNavNum === +week && dayByName === day) return;

    const pathname = `/club/${club}/schedule/${activeBlockOfContent}/${hallId}`;
    //  asPath.split("?")[0];

    router.replace(
      {
        pathname,
        query: {
          activity,
          instructor,
          zone,
          template,
          hallPhone,
          time,
          yearNav,
          monthNav,
          weekNav,
          day: dayByName,
        },
      },
      pathname,
      {
        shallow: true,
      }
    );
  }, [nav, activeBlockOfContent, showRequestBlock]); //asPath,

  return (
    <>
      <HocWithPreloader isLoading={loading} data={halls} withContainer emptyText={t("no_trainings")}>
        <section id="schedule" className="schedule-section main-padding-block">
          <div className="container">
            {halls.length > 1 && (
              <ClubSearchSwiper newParams={{ activeSlideKey: `${hallId}` }} styles={"schedule-section__swiper"}>
                {halls.map(({ id, phone, title }) => {
                  return (
                    <div
                      onClick={() => changeActiveId(id, phone)}
                      className={
                        hallId === id ? "club-swiper-search-element active-club-swiper-search-element" : "club-swiper-search-element"
                      }
                      key={id}
                    >
                      {title}
                    </div>
                  );
                })}
              </ClubSearchSwiper>
            )}

            {zones?.length > 1 && (
              <ClubSearchSwiper newParams={{ activeSlideKey: `${zone}` }} blockStyles={{ marginTop: "10px", marginBottom: "-20px" }}>
                <div
                  onClick={() => setZone(0)}
                  className={clsx("club-swiper-search-element", {
                    "active-club-swiper-search-element": zone == 0,
                  })}
                >
                  {t("all_zones")}
                </div>

                {zones.map(({ id, title }) => {
                  return (
                    <div
                      onClick={() => setZone(id)}
                      className={clsx("club-swiper-search-element", {
                        "active-club-swiper-search-element": zone === id,
                      })}
                      key={id}
                    >
                      {title}
                    </div>
                  );
                })}
              </ClubSearchSwiper>
            )}
          </div>

          <ScheduleBlock
            activities={activities}
            instructors={instructors}
            templates={templates}
            allEvents={allEvents}
            timeClose={timeClose}
            isShowCalendar={showRequestBlock}
            nav={nav}
            setSelectedDay={setSelectedDay}
            setWeekNav={setWeekNav}
            setMonthNav={setMonthNav}
            setDayNav={setDayNav}
            activeBlockOfContent={activeBlockOfContent}
            setActiveBlockOfContent={setActiveBlockOfContent}
            activeHallsetGroupeId={hallId}
            elementBySize={elementBySize}
            activity={activity}
            instructor={instructor}
            template={template}
            time={time}
            setActivity={setActivity}
            setTemplate={setTemplate}
            setInstructor={setInstructor}
            toggleButtons={toggleButtons}
            setToggleButtons={setToggleButtons}
            queryOptions={queryOptions}
            setEvents={setEvents}
            dayStartTime={dayStartTime}
            eveningStartTime={eveningStartTime}
          />
        </section>
      </HocWithPreloader>
      <style jsx global>{`
        @import "./ScheduleSection.scss?s1";
      `}</style>
    </>
  );
}

export default ScheduleSection;
