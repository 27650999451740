import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "next-i18next";

function DesctopBlock({
  descriptionHtml,
  dateTime,
  description,
  timeTitle,
  priceTitle,
}) {
  const [t] = useTranslation();

  return (
    <div className="training-about__row">
      <div className="training-about__column">
        <div
          className="training-about__description training-about-column description-html"
          style={
            descriptionHtml
              ? {}
              : { textAlign: "justify", whiteSpace: "pre-wrap" }
          }
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      </div>
      <div className="training-about__column">
        <div className="training-about__info about-info">
          <div className="training-about__info-item">
            <div className="about-info__title">{t("date_and_time")}</div>
            <div className="about-info__text">{dateTime}</div>
          </div>

          {timeTitle && (
            <div className="training-about__info-item">
              <div className="about-info__title">{t("duration")}</div>
              <div className="about-info__text">{timeTitle}</div>
            </div>
          )}

          {priceTitle && (
            <div className="training-about__info-item">
              <div className="about-info__title">{t("single_visit_price")}</div>
              <div className="about-info__text">{priceTitle}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

DesctopBlock.propTypes = {
  description: PropTypes.string,
  descriptionHtml: PropTypes.bool,
  dateTime: PropTypes.string,
  priceTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  timeTitle: PropTypes.string,
  locale: PropTypes.string,
};
DesctopBlock.defaultProps = {
  description: "",
  descriptionHtml: false,
  dateTime: "",
  priceTitle: "",
  timeTitle: "",
  locale: "",
};

export default DesctopBlock;
